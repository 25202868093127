// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-auth-callback-tsx": () => import("./../../../src/pages/auth_callback.tsx" /* webpackChunkName: "component---src-pages-auth-callback-tsx" */),
  "component---src-pages-booking-auth-tsx": () => import("./../../../src/pages/booking_auth.tsx" /* webpackChunkName: "component---src-pages-booking-auth-tsx" */),
  "component---src-pages-booking-success-tsx": () => import("./../../../src/pages/booking-success.tsx" /* webpackChunkName: "component---src-pages-booking-success-tsx" */),
  "component---src-pages-bookings-tsx": () => import("./../../../src/pages/bookings.tsx" /* webpackChunkName: "component---src-pages-bookings-tsx" */),
  "component---src-pages-breakfast-tsx": () => import("./../../../src/pages/breakfast.tsx" /* webpackChunkName: "component---src-pages-breakfast-tsx" */),
  "component---src-pages-buchen-tsx": () => import("./../../../src/pages/buchen.tsx" /* webpackChunkName: "component---src-pages-buchen-tsx" */),
  "component---src-pages-data-privacy-tsx": () => import("./../../../src/pages/data-privacy.tsx" /* webpackChunkName: "component---src-pages-data-privacy-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-email-verification-success-tsx": () => import("./../../../src/pages/email-verification-success.tsx" /* webpackChunkName: "component---src-pages-email-verification-success-tsx" */),
  "component---src-pages-extras-tsx": () => import("./../../../src/pages/extras.tsx" /* webpackChunkName: "component---src-pages-extras-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interior-tsx": () => import("./../../../src/pages/interior.tsx" /* webpackChunkName: "component---src-pages-interior-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-lage-tsx": () => import("./../../../src/pages/lage.tsx" /* webpackChunkName: "component---src-pages-lage-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-save-vilhelm-7-tsx": () => import("./../../../src/pages/save-vilhelm7.tsx" /* webpackChunkName: "component---src-pages-save-vilhelm-7-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */),
  "component---src-pages-voucher-success-tsx": () => import("./../../../src/pages/voucher-success.tsx" /* webpackChunkName: "component---src-pages-voucher-success-tsx" */),
  "component---src-pages-wohnen-tsx": () => import("./../../../src/pages/wohnen.tsx" /* webpackChunkName: "component---src-pages-wohnen-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

